import React from 'react';

import Layout from '../components/Layout';

const herobg = '#1c2841', herofcolor='#efefef';
const IndexPage = () => (
  <Layout>
    <div id="main" className='col-12' >
        <div className='row' style={{backgroundColor:herobg, color:herofcolor}}>
            <header className='col-9 inner' style={{margin:'.25em auto'}}>
                <h3 >Not Found</h3>
                <h1 >Not a valid URL</h1>
            </header>
        </div>
      <div className="inner">
        
      </div>
    </div>
  </Layout>
);

export default IndexPage;
